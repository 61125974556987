import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import BannerClients from '../components/BannerClients'
import Layout from '../components/layout'
import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const Clients = (props) => (
   <Layout>
        <Helmet
            title="Clients"
            meta={[
                { name: 'description', content: 'Clients Page' },
                { name: 'keywords', content: 'Clients Page' },
            ]}
        >
        </Helmet> 
        <BannerClients />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Our experience</h2>
                    </header>
                        <p><strong>Nubizzi</strong> works to assist organizations to rapidly deliver new services to their clients and gain a competitive advantage in their relative markets. Our customers are flag-ship companies in several sectors.
                        </p>
                          <hr />
                    <header className="major">
                        <h3>Rural Servicios Informáticos (RSI)</h3>
                    </header>
                       <Link to="/references" className="image left">
                         <img src={pic08} alt="" />
                       </Link>
                       <p>Rural Servicios Informáticos, established in 1986, is the company within Caja Rural Group in charge of defining and implementing our common strategy in all aspects relating to automatic data processing in rural savings banks. The goal of RSI is to operate a common center for computer services for the members of rural savings banks, creating the first experience of total applications outsourcing in Spain and offering support to over 70 of these institutions.
                       </p>
                       <p>RSI plays an important role in the computing industry for financial institutions in Spain, with its figures making it one of the leaders:
                       </p>
                       <p><ul><ul><ul><ul>
                          <li>Over 3,500 Offices.</li>
                          <li>Over 3,500 ATMs.</li>
                          <li>Over 3 million Cards.</li>
                          <li>Nearly 70,000 Shops.</li>
                          <li>Nearly 80,000 Point of sale terminals.</li>
                          <li>Over 7 million Clients.</li>
                       </ul></ul></ul></ul></p>
                       <ul className="actions">
                          <li><Link to="/references" className="button small">Learn more</Link></li>
                       </ul>
                       <hr />
                    <header className="major">
                       <h3>Electronic Identification (eID)</h3>
                    </header>
                       <Link to="/references" className="image left">
                         <img src={pic09} alt=""/>
                       </Link>
                       <p>Electronic Identification (eID) is a Software Vendor disrupting Digital Identification and e-Signature industry. They are also an eTrust Service Provider in order to strengthen the services provision to fit most stringent regulation worldwide.
                       </p>
                       <p>They provide VideoID which is the first solution that identifies people by video in real time with the same level of technical security and legal compliance as face-to-face identification.
                       </p>
                       <ul className="actions">
                          <li><Link to="/references" className="button small">Learn more</Link></li>
                       </ul>
                       <hr />
                    <header className="major">
                       <h3>Multinational IT company</h3>
                    </header>
                       <p>A global information technology firm that provides consulting, system integration, outsourcing and solutions. It has customers in a wide array of industries and markets worldwide. It also develops products and services form markets such as:</p>
	               <ul><ul><ul><ul>
	                  <li>Financial services.</li> 
	                  <li>Telecommunications.</li> 
	                  <li>Health.</li> 
	                  <li>Manufacturing.</li> 
	                  <li>Oil and gas.</li>
	                  <li>Posts and logistics.</li>
	                  <li>Retail and consumer services.</li>
	                  <li>Transportation and utilities.</li>
	               </ul></ul></ul></ul>
                       
                       <ul className="actions">
                          <li><Link to="/references" className="button small">Learn more</Link></li>
                       </ul>
                       <hr />
                    <header className="major">
                       <h3>International Private Cloud Provider</h3>
                    </header>
	               <p>A multinational company that provides the following services to customers in North America and Europe:</p>
                       <ul><ul><ul><ul>
                           <li>Web hosting, cloud hosting, dedicated servers, and infrastructure as a service.</li>
                           <li>Innovation – helping customers with new ideas and their implementation.</li>
                           <li>Cybersecurity, Identity management, and data protection.</li>
                           <li>Consulting – the areas of focus include project management and strategy.</li>
                           <li>Website development – design, deploy, protect and host websites.</li>
                           <li>Application development, hosting, and maintenance, incl. mobility.</li>
                           <li>Analytics – helping clients improve their business performance by KPIs (key performance indicators) and presenting key business results.</li>
                       </ul></ul></ul></ul>
                       <ul className="actions">
                          <li><Link to="/references" className="button small">Learn more</Link></li>
                       </ul>
                </div>
            </section>
        </div>
      </Layout>

)

export default Clients
